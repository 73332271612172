import { render, staticRenderFns } from "./OrdersOverviewV2.vue?vue&type=template&id=0410af6a&scoped=true"
import script from "./OrdersOverviewV2.vue?vue&type=script&setup=true&lang=js"
export * from "./OrdersOverviewV2.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0410af6a",
  null
  
)

export default component.exports